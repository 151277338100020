export const tokenButtonMixin = {
    components: {
        BaseTokenButton: () => import('@/components/Tokens/Buttons/BaseTokenButton.vue'),
    },
    props: {
        tokenForm: {
            type: Object,
            required: true,
        },
        step: {
            type: Object,
            required: true,
        },
        formId: {
            type: String,
            required: true,
        },
        tokenError: {
            type: Object,
        },
    },
    methods: {
        updateError() {
            this.$emit('update:tokenError', this.localError)
        },
    },
}
