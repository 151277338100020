<template>
    <base-token-button :step="step" :form-id="formId" :authorize="authorize" @update:error="updateError" />
</template>

<script>
import BaseTokenButton from './BaseTokenButton.vue'
import {tokenButtonMixin} from '@/mixins/tokenButtonMixin'

export default {
    components: {BaseTokenButton},
    mixins: [tokenButtonMixin],
    methods: {
        authorize() {
            this.step.check()
            this.$forceUpdate()

            if (!this.step.invalid) {
                this.localError = {
                    state: null,
                    message: null,
                }
                this.updateError()

                const data = {
                    ...this.tokenForm,
                }

                this.$store.commit('loading/PROCESSING', `Authorizing...`)
                this.$store
                    .dispatch('magento/upsertToken', data)
                    .then(response => {
                        this.$emit('authorizedSuccess')
                        this.$store.commit('loading/PROCESSED')
                    })
                    .catch(exception => {
                        this.localError = {
                            state: false,
                            message: exception.response.data.data,
                        }
                        this.updateError()
                        this.$emit('authorizedError')
                        this.$store.commit('loading/PROCESSED')
                    })
            }
        },
    },
}
</script>

<style></style>
