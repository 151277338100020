<template>
    <b-button @click="authorize" :disabled="step.checked && step.invalid" variant="primary" :id="`${formId}-finish`">
        <i class="icon-check"></i>
        <span> Finish</span>
    </b-button>
</template>

<script>
export default {
    props: {
        step: {
            type: Object,
            required: true,
        },
        formId: {
            type: String,
            required: true,
        },
        authorize: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            localError: {
                state: null,
                message: null,
            },
        }
    },
    methods: {
        updateError() {
            this.$emit('update:error', this.localError)
        },
    },
}
</script>

<style></style>
